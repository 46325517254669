
require("slick-carousel/slick/slick")

function setNewHeight() {
    var i = 2;

    $(".block__content")
        .each(function () {
            if (i % 3 === 0) {
                var h = $(this).outerHeight();
                var p = $(this).parent().parent().prev(".col-xs-12").find(".block__content").outerHeight();
                var n = $(this).parent().parent().next(".col-xs-12").find(".block__content").outerHeight();
                var max = Math.max(h, n, p);
                if ($(this).parent().hasClass('block--links') && $(this).parent().parent().prev(".col-xs-12").find('.block').hasClass('block--links')) {
                    if (window.innerWidth < 768) {
                        $(this).css('min-height', '');
                        $(this)
                            .parent()
                            .parent()
                            .prev(".col-xs-12")
                            .find(".block__content")
                            .css('min-height', '');
                        $(this)
                            .parent()
                            .parent()
                            .next(".col-xs-12")
                            .find(".block__content")
                            .css('min-height', '');
                    } else {
                        $(this).css('min-height', max + 'px');
                        $(this)
                            .parent()
                            .parent()
                            .prev(".col-xs-12")
                            .find(".block__content")
                            .css('min-height', max + 'px');
                        $(this)
                            .parent()
                            .parent()
                            .next(".col-xs-12")
                            .find(".block__content")
                            .css('min-height', max + 'px');
                    }
                }
            }
            if ($(this).parent().hasClass('block--links')) {
                i++;
            }
        });
}

$(() => {
    setNewHeight();
})

$(window).resize(() => {
    setNewHeight();
});